import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/Clubs/Humanity/2024/Inhouse/1.jpg';
// import p2 from 'assests/Photos/Clubs/Humanity/2024/Inhouse/2.jpg';
// import p3 from 'assests/Photos/Clubs/Humanity/2024/Inhouse/3.jpg';
// import p4 from 'assests/Photos/Clubs/Humanity/2024/Inhouse/4.jpg';


// import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';


import { Typography } from '@mui/material';
import Container from 'components/Container';
// import Sidebar2023 from './Sidebar2023';
import Sidebar2023 from 'views/Humanity2023/Sidebar2023';
import Sidebar2022 from 'views/HumanityClub/components/Sidebar2022';
import Sidebar2024 from '../Humanity2023/Sidebar2024';


const InhouseMun2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/association/Humanity/Inhouse/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/association/Humanity/Inhouse/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/association/Humanity/Inhouse/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/association/Humanity/Inhouse/4.webp`;


  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
      
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h5' align='center' >
      Inhouse MUN- 2024  
                                </Typography>
                                <br></br>
                                {/* <Typography variant='h7' align='justify' >
                                Class 4: Diversity Montage
                                Class 5: Continental Montage 

                                </Typography> */}
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 8-12  &  Date: 19 june 2024
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Humanities Department organised the second edition of NPSYPR Inhouse MUN-2024 (Model United Nations) on Tuesday 19 June 2024 for the students of classes 8 to 12. This was an educational simulation of diplomacy, understanding of international relationship and crisis. Delegations representing different countries participated in the session forming Human Rights Committee to debate on the topic ‘Ensuring Transparency and Accountability in Content Moderation Practices of Digital Platform’. The MUN session showcased students' dedication to diplomacy, critical thinking, and global awareness. Participants had an enriching experience on how to tackle global issues diplomatically. The event instilled values of cooperation and mutual respect amongst the student body. 
        <br></br>
        Overall, this session was taken in a good stride by the participants who deliberated with great vigour and energetically portrayed their thoughts and opinions during the session. It exemplified the spirit of collaborative learning and global citizenship, setting a benchmark for future diplomatic simulations.
        <br></br>
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
       “ALL FOR MUN, MUN FOR ALL”

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2024 />
             </Box>

             <Box marginBottom={4}>
               <Sidebar2022 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default InhouseMun2024;